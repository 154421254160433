import { SEARCH_TYPES } from "./../types";
import { getInitialState } from "./../context";

export const search = [
  (state, { type, payload }) => {
    switch(type) {
      case SEARCH_TYPES.SET_QUERY:
      case SEARCH_TYPES.SET_SORT:
      case SEARCH_TYPES.SET_FILTER:
      case SEARCH_TYPES.SET_RESULTS:
        return {
          ...state,
          ...payload,
        };
      default:
        return state;
    };
  },
  getInitialState().search,
];
