import { createContext } from "react";
import { IS_CLIENT, LOCALSTORAGE_STATE_KEY } from "~/constants";

export const initialState = {
  user: {},
  shop: {
    recentlyViewed: [],
  },
  cart: {
    items: [],
    coupons: [],
    subTotal: {
      display: "",
      value: 0,
    },
    grandTotal: 0,
    discount: {
      value: 0,
      coupon: "",
      code: "",
    },
  },
  search: {
    query: "",
  },
};

export const getInitialState = () => {
    const previousState = IS_CLIENT && localStorage
      .getItem(LOCALSTORAGE_STATE_KEY);

    return previousState
      ? JSON.parse(previousState)
      : initialState;
  }

export const StoreContext = createContext(getInitialState());
