// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-become-a-seller-js": () => import("./../../../src/pages/become-a-seller.js" /* webpackChunkName: "component---src-pages-become-a-seller-js" */),
  "component---src-pages-best-sellers-js": () => import("./../../../src/pages/best-sellers.js" /* webpackChunkName: "component---src-pages-best-sellers-js" */),
  "component---src-pages-cards-shopify-product-handle-js": () => import("./../../../src/pages/cards/{ShopifyProduct.handle}.js" /* webpackChunkName: "component---src-pages-cards-shopify-product-handle-js" */),
  "component---src-pages-collections-shopify-collection-handle-js": () => import("./../../../src/pages/collections/{ShopifyCollection.handle}.js" /* webpackChunkName: "component---src-pages-collections-shopify-collection-handle-js" */),
  "component---src-pages-designers-shopify-product-vendor-slug-js": () => import("./../../../src/pages/designers/{ShopifyProduct.vendorSlug}.js" /* webpackChunkName: "component---src-pages-designers-shopify-product-vendor-slug-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latest-js": () => import("./../../../src/pages/latest.js" /* webpackChunkName: "component---src-pages-latest-js" */),
  "component---src-pages-mail-with-love-js": () => import("./../../../src/pages/mail-with-love.js" /* webpackChunkName: "component---src-pages-mail-with-love-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-shipping-policies-js": () => import("./../../../src/pages/shipping-policies.js" /* webpackChunkName: "component---src-pages-shipping-policies-js" */),
  "component---src-pages-story-js": () => import("./../../../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

