// SHOP
export const CART_TYPES = {
  SET_CART_ITEMS: "cart/set_cart_items",
  REMOVE_FROM_CART: "cart/remove_from_cart",
  UPDATE_QUANTITY: "cart/update_quantity",
  UPDATE_CART_TOTALS: "cart/update_cart_totals",
  CLEAR_CART: "cart/clear_cart",
  CLEAR_DISCOUNT: "cart/clear_discount",
  ADD_DISCOUNT: "cart/add_discount",
};

// SEARCH
export const SEARCH_TYPES = {
  SET_QUERY: "search/set_query",
  SET_SORT: "search/set_sort",
  SET_FILTER: "search/set_filter",
  SET_RESULTS: "search/set_results",
};

// SHOP
export const SHOP_TYPES = {
  UPDATE_CATEGORIES: "shop/update_categories",
  SET_RECENTLY_VIEWED: "shop/set_recently_viewed",
};
