import { CART_TYPES } from "./../types";
import { initialState, getInitialState } from "./../context";

export const cart = [
  (state, { type, payload }) => {
    switch(type) {
      case CART_TYPES.SET_CART_ITEMS:
        return {
          ...state,
          items: payload,
        };
      case CART_TYPES.UPDATE_CART_TOTALS:
        return {
          ...state,
          ...payload,
        };
      case CART_TYPES.CLEAR_DISCOUNT:
        return {
          ...state,
          discount: initialState.cart.discount,
        }
      case CART_TYPES.ADD_DISCOUNT:
        return {
          ...state,
          discount: payload || {},
        }
      case CART_TYPES.REMOVE_FROM_CART:
      case CART_TYPES.UPDATE_QUANTITY:
      case CART_TYPES.CLEAR_CART:
        return {
          ...state,
          ...payload,
        };
      default:
        return state;
    };
  },
  getInitialState().cart,
];
