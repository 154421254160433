import { CART_TYPES } from "./../types";

export const updateCartItems = cartItems => ({
  type: CART_TYPES.SET_CART_ITEMS,
  payload: cartItems || [],
})

export const updateCartTotals = totals => ({
  type: CART_TYPES.UPDATE_CART_TOTALS,
  payload: totals,
})

export const clearDiscount = () => ({
  type: CART_TYPES.CLEAR_DISCOUNT,
});

export const addDiscount = discount => ({
  type: CART_TYPES.ADD_DISCOUNT,
  payload: discount,
});
