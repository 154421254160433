export const CLOUDINARY_URL = "https://res.cloudinary.com/thegoodsnail/image/upload/";
export const IS_CLIENT = typeof window !== "undefined";
export const IS_DEBUG = process.env.NODE_ENV !== "production";

// Stripe
export const DEFAULT_CURRENCY = "USD";
export const CART_SUCCESS_URL = `${process.env.GATSBY_SITE_URL}/thank-you`;
export const CART_CANCEL_URL = `${process.env.GATSBY_SITE_URL}/cancel`;

// State
export const LOCALSTORAGE_STATE_KEY = "thegoodsnail:state";
