import { SHOP_TYPES } from "./../types";
import { getInitialState } from "./../context";

export const shop = [
  (state, { type, payload }) => {
    switch(type) {
      case SHOP_TYPES.UPDATE_CATEGORIES:
      case SHOP_TYPES.SET_RECENTLY_VIEWED:
        return {
          ...state,
          ...payload,
        };
      default:
        return state;
    };
  },
  getInitialState().shop,
];
