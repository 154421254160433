import React, { useContext, useEffect, useReducer } from "react";
import combineReducers from "react-combine-reducers";

import { IS_CLIENT, LOCALSTORAGE_STATE_KEY } from "~/constants";
import { cart, search, shop } from "./reducers";
import { StoreContext } from "./context";

const [reducers, initialState] = combineReducers({
  cart,
  search,
  shop,
});

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, initialState);

  useEffect(() => {
    if (IS_CLIENT) {
      localStorage.setItem(LOCALSTORAGE_STATE_KEY, JSON.stringify(state));
    }
  }, [state]);

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);

export const withStore = Component => props => (
  <StoreProvider>
    <Component {...props} />
  </StoreProvider>
);
